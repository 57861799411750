<template>
  <div style="padding: 0 3%">
    <el-form :model="form" ref="form" :rules="rules" :inline="true" label-width="150px"
             style="margin-top: 5%; width: 80%;">
      <el-form-item label="公司名称" prop="unitName">
        <el-input v-model="form.unitName"></el-input>
      </el-form-item>
      <el-form-item label="公司通讯地址" prop="unitAddress">
        <el-input v-model="form.unitAddress"></el-input>
      </el-form-item>
      <el-form-item label="公司所在地" prop="regionId">
        <el-select v-model="form.regionId" clearable>
          <el-option
              v-for="item in regionItems"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="公司注册地址" prop="unitRegisterAddress">
        <el-input v-model="form.unitRegisterAddress"></el-input>
      </el-form-item>
	  
	  <el-row>
		  <el-form-item label="公司税号" prop="dutyNumber">
			<el-input v-model="form.dutyNumber" maxlength="18"></el-input>
		  </el-form-item>
		  <el-form-item label="公司电话" prop="unitTel">
			<el-input v-model="form.unitTel" maxlength="18"></el-input>
		  </el-form-item>
	  </el-row>
      <el-form-item label="联系人" prop="contactName">
        <el-input v-model="form.contactName"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="contactWay">
        <el-input v-model="form.contactWay"></el-input>
      </el-form-item>
      <el-form-item label="公司法人" prop="legalPersonName">
        <el-input v-model="form.legalPersonName"></el-input>
      </el-form-item>
	  <el-row>
		  <el-form-item label="法人联系方式" prop="legalPersonMobile">
		    <el-input v-model="form.legalPersonMobile"></el-input>
		  </el-form-item>
		  <el-form-item label="法人身份证" prop="legalPersonIdentity">
		    <el-input v-model="form.legalPersonIdentity"></el-input>
		  </el-form-item>
	  </el-row>
      
      <el-row>
		  <el-form-item label="法人身份证正面" prop="legalPersonPhoto">
		    <el-upload v-model="form.legalPersonPhoto" style="width: 300px;"
		               class="upload-demo"
		               action=""
		               :http-request="handlePreview3"
		               :on-remove="handleRemove3"
		               :file-list="fileListPhoto"
		               :limit="1"
		               :show-file-list="true"
		               accept="image/*"
		               list-type="picture">
		      <el-button size="small" type="primary">点击上传</el-button>
		      <div slot="tip" class="el-upload__tip">只能上传图片类型文件，单次只能上传一张图片</div>
		    </el-upload>
		  </el-form-item>
		  
		  <el-form-item label="法人身份证反面" prop="legalPersonPhotoVerso">
		    <el-upload v-model="form.legalPersonPhotoVerso" style="width: 300px;"
		               class="upload-demo"
		               action=""
		               :http-request="handlePreview4"
		               :on-remove="handleRemove4"
		               :file-list="fileListPhotoVerso"
		               :limit="1"
		               :show-file-list="true"
		               accept="image/*"
		               list-type="picture">
		      <el-button size="small" type="primary">点击上传</el-button>
		      <div slot="tip" class="el-upload__tip">只能上传图片类型文件，单次只能上传一张图片</div>
		    </el-upload>
		  </el-form-item>
	  </el-row>

      <el-form-item label="开户银行" prop="bankName">
        <el-input v-model="form.bankName"></el-input>
      </el-form-item>
      <el-form-item label="开户账号" prop="bankAccount">
        <el-input v-model="form.bankAccount"></el-input>
      </el-form-item>

      <el-form-item label="收款账号开户行" prop="payeeBankName">
        <el-input v-model="form.payeeBankName"></el-input>
      </el-form-item>
      <el-form-item label="收款账号" prop="payeeBankAccount">
        <el-input v-model="form.payeeBankAccount"></el-input>
      </el-form-item>

      <el-form-item label="营业执照" prop="businessLicense">
        <el-upload v-model="form.businessLicense"  style="width: 300px;"
                   class="upload-demo"
                   action=""
                   :http-request="handlePreview1"
                   :on-remove="handleRemove1"
                   :file-list="fileListBL"
                   :limit="1"
                   :show-file-list="true"
                   accept="image/*"
                   list-type="picture">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传图片类型文件，单次只能上传一张图片</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="道路许可证" prop="roadPermit">
        <el-upload v-model="form.roadPermit" style="width: 300px;"
                   class="upload-demo"
                   action=""
                   :http-request="handlePreview2"
                   :on-remove="handleRemove2"
                   :file-list="fileListRP"
                   :limit="1"
                   :show-file-list="true"
                   accept="image/*"
                   list-type="picture">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传图片类型文件，单次只能上传一张图片</div>
        </el-upload>
      </el-form-item>

      <el-form-item label="申请状态" v-if="form.isCorrect==-1">
        <span style="width: 300px;">审批驳回</span>
      </el-form-item>
      <el-form-item label="驳回原因" v-if="form.isCorrect==-1">
        <span>{{ form.rejectReason }}</span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="margin-top: 5%;margin-left: 30%;">
      <!--          <el-button @click="cancel">取 消</el-button>-->
      <el-button type="primary" @click="formSubmit">提 交</el-button>
    </div>

    <div style="height: 100px;"></div>
  </div>

</template>
<script>
export default {
  name: 'userPolish',
  props: ['userInfo'],
  data() {
    return {
      form: {
        unitName: '',
        unitAddress: '',
        unitRegisterAddress: '',
        contactName: '',
        contactWay: '',
        legalPersonName: '',
        legalPersonIdentity: '',
        legalPersonMobile: '',
        legalPersonPhoto: '',
        legalPersonPhotoVerso: '',
        bankName: '',
        bankAccount: '',
        payeeBankName: '',
        payeeBankAccount: '',
        businessLicense: '',
        roadPermit: '',
        isCorrect: '',
        regionId: '',
		dutyNumber:'',
		unitTel:'',
      },
      rules: {
        unitName: [{required: true, message: '请输入公司名称', trigger: 'blur'}],
        unitAddress: [{required: true, message: '请输入公司地址', trigger: 'blur'}],
        contactName: [{required: true, message: '请输入联系人', trigger: 'blur'},
          {min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur'}],
        contactWay: [{required: true, message: '请输入联系方式', trigger: 'blur'},
          {min: 7, max: 50, message: '长度在 7 到 50 个字符', trigger: 'blur'}],
        legalPersonName: [{required: true, message: '请输入法人姓名', trigger: 'blur'},
          {min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur'}],
        legalPersonIdentity: [{required: true, message: '请输入法人身份证', trigger: 'blur'},
          {min: 18, max: 18, message: '身份证长度 18 个字符', trigger: 'blur'}],
        bankName: [{required: true, message: '请输入开户行', trigger: 'blur'},
          {max: 50, message: '长度最大 50 个字符', trigger: 'blur'}],
        bankAccount: [{required: true, message: '请输入开户行账号', trigger: 'blur'},
          {max: 50, message: '长度最大 50 个字符', trigger: 'blur'}],
        businessLicense: [{required: true, message: '请上传营业执照', trigger: 'blur'}],
        roadPermit: [{required: true, message: '请上传道路许可证', trigger: 'blur'}],
        regionId: [{required: true, message: '请选择公司所在地', trigger: 'changge'}],
        unitRegisterAddress: [{required: true, message: '请输入公司注册地址', trigger: 'blur'}],
        legalPersonMobile: [{required: true, message: '请输入法人联系方式', trigger: 'blur'}],
        legalPersonPhoto: [{required: true, message: '请上传法人身份证正面照片', trigger: 'blur'}],
        legalPersonPhotoVerso: [{required: true, message: '请上传法人身份证反面照片', trigger: 'blur'}],
        payeeBankName: [{required: true, message: '请输入收款账号开户行', trigger: 'blur'}],
        payeeBankAccount: [{required: true, message: '请输入收款账号', trigger: 'blur'}],
		dutyNumber: [{required: true, message: '请输入公司税号', trigger: 'blur'}],
		unitTel: [{required: true, message: '请输入公司电话', trigger: 'blur'}],
      },
      fileListBL: [],
      fileListRP: [],
      fileListPhoto: [],
      fileListPhotoVerso: [],

      baseBLPath: '',
      baseRPPath: '',
      basePhotoPath: '',
      basePhotoVersoPath: '',
      regionItems: [],
    }
  },
  created() {
    this.initRegionItem();
  },
  mounted() {
	const userInfo = JSON.parse(JSON.stringify(this.userInfo));
	this.form = this.userInfo;
    if (userInfo.businessLicense) {
      this.baseBLPath = userInfo.businessLicense;
    }
    if (userInfo.roadPermit) {
      this.baseRPPath = userInfo.roadPermit;
    }
    if (userInfo.legalPersonPhoto) {
      this.basePhotoPath = userInfo.legalPersonPhoto;
    }
	if (userInfo.legalPersonPhotoVerso) {
	  this.basePhotoVersoPath = userInfo.legalPersonPhotoVerso;
	}

    if (this.baseBLPath) {
      this.fileListBL.push({url: this.baseBLPath});
    }
    if (this.baseRPPath) {
      this.fileListRP.push({url: this.baseRPPath});
    }
    if (this.basePhotoPath) {
      this.fileListPhoto.push({url: this.basePhotoPath})
    }
	if (this.basePhotoVersoPath) {
	  this.fileListPhotoVerso.push({url: this.basePhotoVersoPath})
	}

    this.form.businessLicense = userInfo.baseBLPath;
    this.form.roadPermit = userInfo.baseRPPath;
    this.form.legalPersonPhoto = userInfo.basePhotoPath;
    this.form.legalPersonPhotoVerso = userInfo.basePhotoVersoPath;
  },
  methods: {
    initRegionItem: function () {
      this.HTTP.get('/base/initRegionItem').then(res => {
        if (res.data.code == 1) {
          this.regionItems = res.data.rows;
        }
      }).catch(err => {
        this.$message.error(err);
      })
    },
    // cancel:function(){
    // 	this.$emit('cancel',0);
    // },
    formSubmit: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$confirm('请核对信息是否无误！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.form.isCorrect == -1) {
              this.form.isCorrect = 0;
            }
            this.HTTP.post('/user/polish', this.form).then(res => {
              if (res.data.code == 1) {
                this.$message.success("操作成功");
                // this.$emit('cancel',0);
              } else {
                this.$message.error(res.data.message);
              }
            }).catch(err => {
              this.$message.error(err);
            })
          })
        }
      })
    },
    //文件上传
    handlePreview1: function (file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.HTTP.upload('/file/uploadImage', formData).then(res => {
        if (res.data.code == 1) {
          this.$message({message: '上传成功', type: 'success'});
          this.form.businessLicense = res.data.data.url;
        } else {
          this.$message(res.data.message);
        }
      })
    },
    handlePreview2: function (file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.HTTP.upload('/file/uploadImage', formData).then(res => {
        if (res.data.code == 1) {
          this.$message({message: '上传成功', type: 'success'});
          this.form.roadPermit = res.data.data.url;
        } else {
          this.$message(res.data.message);
        }
      })
    },
    handleRemove1: function (file) {
      this.form.businessLicense = '';
    },
    handleRemove2: function (file) {
      this.form.roadPermit = '';
    },
    handleRemove3: function (file) {
      this.form.legalPersonPhoto = '';
    },
    handlePreview3: function (file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.HTTP.upload('/file/uploadImage', formData).then(res => {
        if (res.data.code == 1) {
          this.$message({message: '上传成功', type: 'success'});
          this.form.legalPersonPhoto = res.data.data.url;
        } else {
          this.$message(res.data.message);
        }
      })
    },
	handlePreview4: function (file) {
	  let formData = new FormData();
	  formData.append('file', file.file);
	  this.HTTP.upload('/file/uploadImage', formData).then(res => {
	    if (res.data.code == 1) {
	      this.$message({message: '上传成功', type: 'success'});
	      this.form.legalPersonPhotoVerso = res.data.data.url;
	    } else {
	      this.$message(res.data.message);
	    }
	  })
	},
	handleRemove4: function (file) {
	  this.form.legalPersonPhotoVerso = '';
	},

  }
};
</script>

<style scoped>
.grey-line .el-row {
  border-bottom: 1px solid #dcdfe6;
}

.grey-line .el-row:last-child {
  border-bottom: none;
}

.userInfo_list .grid-content {
  line-height: 20px;
}

.userInfo_list .lost-title {
  font-weight: 400;
  color: #1f2f3d;
  font-size: 20px;
}

.userInfo_list .title-content {
  font-weight: 400;
  color: #1f2f3d;
  font-size: 20px;
  margin-left: 5px;
}

.grid-content {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.complaint-title {
  align-items: center;
  margin-right: 5px;
  font-size: 16px;
  display: flex;
  font-weight: bolder;
  color: #1f2f3d;
  white-space: nowrap;
}

.title-content {
  display: flex;
  align-items: center;
}

.el-input {
  width: 300px;
}

.el-select {
  width: 300px;
}
</style>
